import { Box, Heading, Text } from '@wix/design-system';
import { useTranslation } from '@wix/wix-i18n-config';
import { FunctionComponent, useEffect, useState } from 'react';
import { DATA_HOOKS } from '../../../dataHooks';
import css from './index.module.scss';
import { RenderHTML } from '../../RenderHTML';
import { getRedirectToLoginUrl } from '../../../utils/login';
import { useRouter } from 'next/router';

export enum EmptyStateType {
  FOLLOWING = 'following',
  SEARCH = 'search',
  FOLLOWED_NOT_LOGGED_IN = 'followed-not-logged-in',
}

export type KnownIssuesEmptyStateProps = {
  type: EmptyStateType;
};

export const KnownIssuesEmptyState: FunctionComponent<
  KnownIssuesEmptyStateProps
> = ({ type }) => {
  const { t } = useTranslation();
  const [loginUrl, setLoginUrl] = useState('');
  const { locale } = useRouter();
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setLoginUrl(getRedirectToLoginUrl(locale as string));
    }
  }, [locale]);

  return (
    <Box className={css.emptyState} direction="vertical" align="center">
      {type !== EmptyStateType.FOLLOWED_NOT_LOGGED_IN ? (
        <Heading
          dataHook={DATA_HOOKS.KNOWN_ISSUES_EMPTY_STATE_TITLE}
          className={css.title}
        >
          {t(`known-issues-page.empty-state.${type}.title`)}
        </Heading>
      ) : (
        <RenderHTML
          html={t(`known-issues-page.empty-state.${type}.title`, {
            loginUrl
          })}
          headingElement={{
            dataHook: DATA_HOOKS.KNOWN_ISSUES_EMPTY_STATE_TITLE,
            className: css.title,
            size: 'medium'
          }}
        />
      )}
      <Text
        dataHook={DATA_HOOKS.KNOWN_ISSUES_EMPTY_STATE_SUBTITLE}
        weight="thin"
        size="medium"
        className={css.subtitle}
      >
        {t(`known-issues-page.empty-state.${type}.subtitle`)}
      </Text>
    </Box>
  );
};
