export const ArticleIconMobile = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.9538 12.0996C12.999 11.6009 13 10.953 13 10V8C13 7.04698 12.999 6.39908 12.9538 5.90036C12.9099 5.41539 12.8305 5.17051 12.7321 5C12.5565 4.69596 12.304 4.44349 12 4.26795C11.8295 4.16951 11.5846 4.09011 11.0996 4.04616C10.6009 4.00096 9.95302 4 9 4C8.04698 4 7.39908 4.00096 6.90036 4.04616C6.41539 4.09011 6.17051 4.16951 6 4.26795C5.69596 4.44349 5.44349 4.69596 5.26795 5C5.16951 5.17051 5.09011 5.41539 5.04616 5.90036C5.00096 6.39908 5 7.04698 5 8V10C5 10.953 5.00096 11.6009 5.04616 12.0996C5.09011 12.5846 5.16951 12.8295 5.26795 13C5.44349 13.304 5.69596 13.5565 6 13.7321C6.17051 13.8305 6.41539 13.9099 6.90036 13.9538C7.39908 13.999 8.04698 14 9 14C9.95302 14 10.6009 13.999 11.0996 13.9538C11.5846 13.9099 11.8295 13.8305 12 13.7321C12.304 13.5565 12.5565 13.304 12.7321 13C12.8305 12.8295 12.9099 12.5846 12.9538 12.0996ZM4.40192 4.5C4 5.19615 4 6.13077 4 8V10C4 11.8692 4 12.8038 4.40192 13.5C4.66523 13.9561 5.04394 14.3348 5.5 14.5981C6.19615 15 7.13077 15 9 15C10.8692 15 11.8038 15 12.5 14.5981C12.9561 14.3348 13.3348 13.9561 13.5981 13.5C14 12.8038 14 11.8692 14 10V8C14 6.13077 14 5.19615 13.5981 4.5C13.3348 4.04394 12.9561 3.66523 12.5 3.40192C11.8038 3 10.8692 3 9 3C7.13077 3 6.19615 3 5.5 3.40192C5.04394 3.66523 4.66523 4.04394 4.40192 4.5ZM7 6.5C7 6.22386 7.22386 6 7.5 6H10.5C10.7761 6 11 6.22386 11 6.5C11 6.77614 10.7761 7 10.5 7H7.5C7.22386 7 7 6.77614 7 6.5ZM7.5 8C7.22386 8 7 8.22386 7 8.5C7 8.77614 7.22386 9 7.5 9H8.5C8.77614 9 9 8.77614 9 8.5C9 8.22386 8.77614 8 8.5 8H7.5Z"
      fill="currentColor"
    />
  </svg>
);
