export const ArticleIcon = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.8991 17.764C16.9979 17.0292 17 16.048 17 14.6055V10.6055C17 9.16299 16.9979 8.18172 16.8991 7.4469C16.8042 6.74085 16.6368 6.41383 16.4142 6.19126C16.1916 5.96868 15.8646 5.80131 15.1586 5.70639C14.4237 5.60759 13.4425 5.60547 12 5.60547C10.5575 5.60547 9.57625 5.60759 8.84143 5.70639C8.13538 5.80131 7.80836 5.96868 7.58579 6.19126C7.36322 6.41383 7.19584 6.74085 7.10092 7.4469C7.00212 8.18172 7 9.16299 7 10.6055V14.6055C7 16.048 7.00212 17.0292 7.10092 17.764C7.19584 18.4701 7.36322 18.7971 7.58579 19.0197C7.80836 19.2423 8.13538 19.4096 8.84143 19.5046C9.57625 19.6033 10.5575 19.6055 12 19.6055C13.4425 19.6055 14.4237 19.6033 15.1586 19.5046C15.8646 19.4096 16.1916 19.2423 16.4142 19.0197C16.6368 18.7971 16.8042 18.4701 16.8991 17.764ZM6.87868 5.48415C6 6.36283 6 7.77704 6 10.6055V14.6055C6 17.4339 6 18.8481 6.87868 19.7268C7.75736 20.6055 9.17157 20.6055 12 20.6055C14.8284 20.6055 16.2426 20.6055 17.1213 19.7268C18 18.8481 18 17.4339 18 14.6055V10.6055C18 7.77704 18 6.36283 17.1213 5.48415C16.2426 4.60547 14.8284 4.60547 12 4.60547C9.17157 4.60547 7.75736 4.60547 6.87868 5.48415ZM9 9.10547C9 8.82933 9.22386 8.60547 9.5 8.60547H13.5C13.7761 8.60547 14 8.82933 14 9.10547C14 9.38161 13.7761 9.60547 13.5 9.60547H9.5C9.22386 9.60547 9 9.38161 9 9.10547ZM9.5 11.6055C9.22386 11.6055 9 11.8293 9 12.1055C9 12.3816 9.22386 12.6055 9.5 12.6055H11.5C11.7761 12.6055 12 12.3816 12 12.1055C12 11.8293 11.7761 11.6055 11.5 11.6055H9.5Z"
      fill="currentColor"
    />
  </svg>
);
