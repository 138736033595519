import {
  Article,
  ArticleSortType,
  ArticleType,
  Category,
} from '@wix/answers-api';
import type { NextPage } from 'next';
import { useRouter } from 'next/router';
import { useCallback, useContext, useEffect, useState } from 'react';
import { CategoryPageWrapper } from '../../components/CategoryPageWrapper';
import {
  KNOWN_ISSUES_ITEMS_PER_PAGE,
  KnownIssuesPage,
} from '../../components/KnownIssuesPage';
import { KNOWN_ISSUE_CATEGORY_ID } from '../../constants';
import { Context } from '../../context';
import {
  getCategoryArticles,
  getCategoryByUri,
  getCategoryTranslations,
  getHelpCenterData,
  getPageSeo,
  searchArticles,
} from '../../services/answers';
import { getWixDocsUrl } from '../../services/wix-docs-redirect';
import {
  ArticleSearchResultItem,
  GetWixServerSidePropsFunction,
  HttpStatusCode,
  KNOWN_ISSUES_RELEVANT_RESOLUTIONS,
  PageType,
  PAGES
} from '../../types';
import { withAppServerProps } from '../../utils/appServerProps';
import { findCategoryInTree } from '../../utils/categories';
import { LoggerNamespaces, createLogger } from '../../utils/logger';
import { isProduction } from '@wix/wix-run-mode';
import { isSledUserAgent } from '../../utils/userAgent';

type CategoryPageProps = {
  category: Category;
  categoriesArticles: Record<string, Article[]>;
  knownIssuesArticles: ArticleSearchResultItem[] | null;
  knownIssuesCount: number | null;
};

const CategoryPage: NextPage<CategoryPageProps> = ({
  category,
  categoriesArticles,
  knownIssuesArticles,
  knownIssuesCount,
}) => {
  const { locale } = useRouter();
  const { categoriesTree, answersApi, isLoggedInUser } = useContext(Context);
  const [usersFollowedArticles, setUsersFollowedArticles] = useState<Article[]>(
    []
  );

  const isKnownIssuePage = category.id === KNOWN_ISSUE_CATEGORY_ID;

  const getFollowedArticles = useCallback(async () => {
    try {
      const userFollowedArticleResponse =
        await answersApi.articles.getFollowedArticles({
          locale: locale as string,
          page: 1,
          pageSize: 50,
        });
      setUsersFollowedArticles(userFollowedArticleResponse.items);
    } catch (e) {
      setUsersFollowedArticles([]);
    }
  }, [answersApi, locale]);

  useEffect(() => {
    void (async () => {
      if (isKnownIssuePage && isLoggedInUser) {
        await getFollowedArticles();
      }
    })();
  }, [getFollowedArticles, isKnownIssuePage, isLoggedInUser]);

  const followedKnownIssuesIds = usersFollowedArticles
    .filter((article) => article.type === ArticleType.KNOWN_ISSUE)
    .map((article) => article.id);
  return isKnownIssuePage && knownIssuesArticles ? (
    <KnownIssuesPage
      initKnownIssues={knownIssuesArticles}
      numberOfKnownIssues={knownIssuesCount || 0}
      categoryTree={categoriesTree}
      followedKnownIssuesIds={followedKnownIssuesIds}
    />
  ) : (
    <CategoryPageWrapper
      key={category.id}
      currentCategory={category}
      categories={categoriesTree}
      categoriesArticles={categoriesArticles}
    />
  );
};

export const getServerSideProps: GetWixServerSidePropsFunction =
  withAppServerProps(async ({ req, res, locale, query }) => {
    const { categoryName } = query;
    const logger = createLogger(LoggerNamespaces.Server);

    let category: Category;
    try {
      category = await getCategoryByUri(
        req.aspects,
        `/${categoryName}`,
        locale,
        res.locals.redisClient
      );
    } catch (e: any) {
      const statusError = e.httpStatusCode || e.statusCode || e.code;
      const fullUrl = `https://support.wix.com/${locale}/${categoryName}`;

      if (statusError === HttpStatusCode.NOT_FOUND) {
        const wixDocsUrl = await getWixDocsUrl(res.locals.appContext, fullUrl);

        if (wixDocsUrl) {
          const userAgent = req.headers['user-agent'];
          if (isProduction() && (userAgent && !isSledUserAgent(userAgent))) {
            const logData = {
              from: fullUrl,
              to: wixDocsUrl,
              referrer: req.headers.referer,
            };
            logger.info('Redirecting to Wix Docs', logData);
          }

          return {
            redirect: {
              statusCode: 301,
              destination: wixDocsUrl,
            },
          };
        }
      }
      throw e;
    }

    if (category.uri !== `/${categoryName}`) {
      return {
        redirect: {
          statusCode: HttpStatusCode.MOVED_PERMANENTLY,
          destination: encodeURI(`/${locale}/${category.uri}`),
        },
      };
    }

    const { aspects } = req;

    const { categories: categoriesTree } = await getHelpCenterData(
      req.aspects,
      locale,
      res.locals.redisClient
    );

    const pageSeo = await getPageSeo(
      req.aspects,
      locale,
      PageType.Category,
      res.locals.redisClient
    );
    category = findCategoryInTree(categoriesTree, category.uri) as Category;

    if (!category) {
      logger
        .withRequest(req)
        .error(
          `Category /${locale}/${categoryName} cannot be not found in tree`
        );
      return {
        notFound: true,
      };
    }

    const isKnownIssueCategory = category.id === KNOWN_ISSUE_CATEGORY_ID;

    let categoriesArticles: Record<string, Article[]> = {};
    if (!isKnownIssueCategory) {
      if (!category.children || category.children.length === 0) {
        categoriesArticles = await getCategoryArticles(
          aspects,
          [category.id],
          locale
        );
      } else {
        const childrenWithNoChildren = category.children.filter(
          (child) => !child.children || child.children.length === 0
        );
        const childrenIds = childrenWithNoChildren.map((child) => child.id);

        if (childrenIds.length > 0) {
          categoriesArticles = await getCategoryArticles(
            aspects,
            childrenIds,
            locale
          );
        }
      }
    }

    const translations = await getCategoryTranslations(
      aspects,
      category.id,
      res.locals.redisClient
    );

    const knownIssuesArticles = isKnownIssueCategory
      ? await searchArticles(
        aspects,
        locale,
        {
          page: 1,
          pageSize: KNOWN_ISSUES_ITEMS_PER_PAGE,
          resolutions: KNOWN_ISSUES_RELEVANT_RESOLUTIONS,
          sortType: ArticleSortType.CREATION_DATE_DESC,
          text: '',
        },
        res.locals.redisClient,
        true
      )
      : undefined;

    return {
      props: {
        pageType: isKnownIssueCategory ? PAGES.KNOWN_ISSUES : PAGES.CATEGORY,
        category,
        categoriesTree,
        categoriesArticles,
        pageData: {
          category,
          translations,
        },
        itemId: category.id,
        knownIssuesArticles: knownIssuesArticles?.items || null,
        knownIssuesCount: knownIssuesArticles?.itemsCount || null,
        pageSeo,
      },
    };
  });

export default CategoryPage;
