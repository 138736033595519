import { Category } from '@wix/answers-api';
import { Box, Divider, Pagination } from '@wix/design-system';
import { useHttpClient } from '@wix/fe-essentials-standalone';
import { pageClick, sortAndFilter } from '@wix/bi-logger-customer-care-data/v2';
import { useTranslation } from '@wix/wix-i18n-config';
import { useRouter } from 'next/router';
import { FunctionComponent, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { animateScroll } from 'react-scroll';
import { BI, KNOWN_ISSUE_CATEGORY_ID } from '../../constants';
import { DATA_HOOKS } from '../../dataHooks';
import { useBI } from '../../hooks/useBI';
import {
  ArticleSearchResult,
  ArticleSearchResultItem,
  KNOWN_ISSUES_RELEVANT_RESOLUTIONS,
  SortAndFilterBiMetaData,
} from '../../types';
import { getCategoriesBreadcrumb } from '../../utils/categories';
import { Breadcrumbs } from '../Breadcrumbs';
import { EmptyStateType, KnownIssuesEmptyState } from './EmptyState';
import { KnownIssuesItem } from './KnownIssuesItem';
import { FilterBy, FilterByOption, SearchAndFilterPanel, SortBy } from './SearchAndFilterPanel';
import css from './index.module.scss';
import { Context } from '../../context';

export type KnownIssuesPageProps = {
  initKnownIssues: ArticleSearchResultItem[];
  numberOfKnownIssues: number;
  categoryTree: Category[];
  followedKnownIssuesIds: string[];
};

type FetchNewData = {
  text?: string;
  page?: number;
  sortType?: SortBy;
  fetchFilterBy?: FilterBy;
};

export const KNOWN_ISSUES_ITEMS_PER_PAGE = 12;

const { BREADCRUMBS, KNOWN_ISSUES_SELECTION } = BI.CLICKED_ITEM_TYPES;

export const KnownIssuesPage: FunctionComponent<KnownIssuesPageProps> = ({
  initKnownIssues,
  numberOfKnownIssues,
  categoryTree,
  followedKnownIssuesIds,
}) => {
  const { t } = useTranslation();
  const { isLoggedInUser } = useContext(Context);
  const { locale } = useRouter();
  const httpClient = useHttpClient();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalNumberOfResults, setTotalNumberOfResults] = useState(numberOfKnownIssues);

  const { sendBIEvent } = useBI();

  const [savedSortBy, setSavedSortBy] = useState<SortBy>(SortBy.NEWEST);
  const [savedSearchQuery, setSavedSearchQuery] = useState<string>();
  const [savedFilterBy, setSavedFilterBy] = useState<FilterBy>({status: [], myIssues: false});

  const [filteredKnownIssues, setFilteredKnownIssues] = useState(initKnownIssues);
  const breadCrumbsRef = useRef(null);

  const numberOfPages = useMemo(() => Math.ceil(totalNumberOfResults / KNOWN_ISSUES_ITEMS_PER_PAGE), [totalNumberOfResults]);

  const sendSortAndFilterBi = useCallback(
    async ({ filter_by, sort_by, items_left, search_term_filter }: SortAndFilterBiMetaData) => {
      await sendBIEvent(
        sortAndFilter({
          filter_by,
          sort_by,
          items_left,
          search_term_filter,
          source_name: BI.SOURCE_NAMES.KNOWN_ISSUES,
          kb_lang: locale as string,
        })
      );
    }, [locale]);

  const emptyStateType: EmptyStateType | undefined = useMemo(() => {
    const hasNoResults = filteredKnownIssues.length === 0;
    if (hasNoResults && savedFilterBy.myIssues && !isLoggedInUser) {
      return EmptyStateType.FOLLOWED_NOT_LOGGED_IN;
    } else if ((savedSearchQuery?.length || savedFilterBy.status.length > 0) && hasNoResults) {
      return EmptyStateType.SEARCH;
    } else if (hasNoResults && savedFilterBy.myIssues) {
      return EmptyStateType.FOLLOWING;
    }
    return undefined;
  }, [savedSearchQuery, savedFilterBy.status.length, savedFilterBy.myIssues, filteredKnownIssues.length]);

  const fetchNewData = useCallback(async (fetchData: FetchNewData, biMetaData?: SortAndFilterBiMetaData) => {
    const { text = savedSearchQuery,
            page,
            sortType = savedSortBy,
            fetchFilterBy = savedFilterBy,
    } = fetchData;

    animateScroll.scrollToTop();

    const isEmptyResultsExpected = fetchFilterBy.myIssues && !isLoggedInUser ||
      fetchFilterBy.myIssues && !followedKnownIssuesIds.length;

    let itemsLeft = 0;
    if (isEmptyResultsExpected) {
      setTotalNumberOfResults(0);
      setFilteredKnownIssues([]);
    } else {
      const { data } = await httpClient.get<ArticleSearchResult>('/api/article/search', {
        params: {
          locale,
          text,
          ids: fetchFilterBy.myIssues ? followedKnownIssuesIds : undefined,
          pageSize: KNOWN_ISSUES_ITEMS_PER_PAGE,
          resolutions:
            fetchFilterBy.status.length === 0
              ? KNOWN_ISSUES_RELEVANT_RESOLUTIONS
              : fetchFilterBy.status,
          sortType,
          page: page || currentPage,
        },
      });
      const { items, itemsCount } = data;
      itemsLeft = itemsCount;
      setFilteredKnownIssues(items);
      setTotalNumberOfResults(itemsCount);
    }
    if (biMetaData) {
      /* eslint-disable @typescript-eslint/naming-convention */
      const { filter_by, sort_by, search_term_filter } = biMetaData;
      await sendSortAndFilterBi({ filter_by, sort_by, items_left: itemsLeft, search_term_filter });
    }
  }, [savedSearchQuery, savedFilterBy, savedSortBy, isLoggedInUser, followedKnownIssuesIds, currentPage, httpClient, locale, sendSortAndFilterBi]);

  const onSearchQueryChange = useCallback(async (updatedSearchQuery: string) => {
    if (updatedSearchQuery.length > 0) {
      setSavedSortBy(SortBy.MOST_RELEVANT);
    } else {
      setSavedSortBy(SortBy.NEWEST);
    }

    setSavedSearchQuery(updatedSearchQuery);
    setCurrentPage(1);
    await fetchNewData({ text: updatedSearchQuery, page: 1 }, {
      search_term_filter: `${updatedSearchQuery}`,
    });
  }, [fetchNewData]);

  const onPageChangeEvent = useCallback((page: number) => {
    void (async () => {
      await sendBIEvent(
        pageClick({
          source_name: BI.SOURCE_NAMES.KNOWN_ISSUES,
          kb_lang: locale as string,
          clicked_text: `${page}`,
          clicked_item_type: BI.CLICKED_ITEM_TYPES.PAGE_SELECTION,
        })
      );
      setCurrentPage(page);
      await fetchNewData({ page });
    })();
  }, [locale, fetchNewData]);

  const onApplyFiltersAndSort = useCallback(async (newFilterBy: FilterBy, newSortBy: SortBy, newAddedFilters: FilterByOption[]) => {
    const isNewSortBy = newSortBy !== savedSortBy ? t(`known-issues-page.sort-by.${newSortBy}`) : undefined;
    const biFilterBy = newAddedFilters.length ? newAddedFilters.map((newAddedFilter) => newAddedFilter === 'following'
      ? t('known-issues-page.filter-by.following')
      : t(`known-issues-page.status.${newAddedFilter}`)).join(', ') : undefined;
    setSavedFilterBy(newFilterBy);
    setSavedSortBy(newSortBy);
    await fetchNewData({ fetchFilterBy: newFilterBy, sortType: newSortBy, page: 1 }, { filter_by: biFilterBy, sort_by: isNewSortBy });
  }, [t, fetchNewData, savedSortBy]);

  const sendKnownIssuesBiEvent = useCallback(async (clickedItemType: string, clickedText: string, clickedItemId?: string) => {
    await sendBIEvent(
      pageClick({
        source_name: BI.SOURCE_NAMES.KNOWN_ISSUES,
        kb_lang: locale as string,
        clicked_item_type: clickedItemType,
        clicked_text: clickedText,
        clicked_item_id: clickedItemId,
      })
    );
  }, [locale]);

  const onKnownIssueClick = useCallback(async (knownIssue: ArticleSearchResultItem) => {
    await sendKnownIssuesBiEvent(KNOWN_ISSUES_SELECTION, knownIssue.title, knownIssue.id);
  }, [sendKnownIssuesBiEvent]);

  const onBreadcrumbClick = useCallback(async (category: Category) => {
    await sendKnownIssuesBiEvent(BREADCRUMBS, category.name, category.id);
  }, [sendKnownIssuesBiEvent]);

  const onHomePageClick = useCallback(async (name: string) => {
    await sendKnownIssuesBiEvent(BREADCRUMBS, name);
  }, [sendKnownIssuesBiEvent]);

  const breadcrumbItems = useMemo(() =>
    getCategoriesBreadcrumb(
      categoryTree,
      KNOWN_ISSUE_CATEGORY_ID,
      onBreadcrumbClick,
      true
    ),
    [categoryTree, onBreadcrumbClick]
  );

  return (
    <Box className={css.knownIssuePageWrapper} direction="vertical">
      <div className={css.breadCrumbsKnownIssues}>
        <Breadcrumbs breadcrumbItems={breadcrumbItems} onHomePageClick={onHomePageClick} />
      </div>
      <div ref={breadCrumbsRef}></div>
      <Box
        dataHook={DATA_HOOKS.KNOWN_ISSUES_PAGE}
        className={css.KnownIssuePage}
        direction="vertical"
      >
        <SearchAndFilterPanel
          numberOfKnownIssues={totalNumberOfResults}
          searchQuery={savedSearchQuery}
          filterBy={savedFilterBy}
          sortBy={savedSortBy as SortBy}
          breadCrumbsRef={breadCrumbsRef}
          onSearchQueryChange={onSearchQueryChange}
          onApplyFiltersAndSort={onApplyFiltersAndSort}
        />
        <Divider className={css.KnownIssuePageItemsDivider} />
        <Box className={css.KnownIssuePageItems} direction="vertical" verticalAlign='middle'>
          {!emptyStateType ? filteredKnownIssues.map((knownIssue, index) => (
            <Box key={knownIssue.id} direction="vertical">
              {index > 0 && (
                <Divider className={css.KnownIssuePageItemDivider} />
              )}
              <KnownIssuesItem
                knownIssue={knownIssue}
                locale={locale as string}
                onClick={onKnownIssueClick}
              />
            </Box>
          ))
            : null}

          {emptyStateType ? (
            <KnownIssuesEmptyState type={emptyStateType} />
          ) : null}

          {!emptyStateType && totalNumberOfResults > KNOWN_ISSUES_ITEMS_PER_PAGE ? (
            <Pagination
              className={css.pagination}
              onChange={(e) => onPageChangeEvent(e.page)}
              currentPage={currentPage}
              totalPages={numberOfPages}
              dataHook={DATA_HOOKS.KNOWN_ISSUES_PAGE_PAGINATION}
            />
          ) : null}
        </Box>
      </Box>
    </Box >
  );
};
