import { ArticleResolution } from '@wix/answers-api';
import { Badge, BadgeSkin, Box, Text, Tooltip } from '@wix/design-system';
import { useTranslation } from '@wix/wix-i18n-config';
import Long from 'long';
import moment from 'moment';
import { FunctionComponent, useEffect, useState } from 'react';
import ArrowRightIcon from 'wix-ui-icons-common/ArrowRight';
import ArrowRightSmallIcon from 'wix-ui-icons-common/ArrowRightSmall';
import { KI_PUBLISH_DATE_FORMAT, TOOLTIP_ENTER_DELAY } from '../../../constants';
import { DATA_HOOKS } from '../../../dataHooks';
import { ArticleIcon } from '../../../icons/ArticleIcon';
import { ArticleIconMobile } from '../../../icons/ArticleIconMobile';
import { ArticleSearchResultItem } from '../../../types';
import { InnerLink } from '../../InnerLink';
import 'moment/min/locales';
import css from './index.module.scss';
import { isLong } from '../../../utils/isLong';
export type KnownIssuesItemProps = {
  knownIssue: ArticleSearchResultItem;
  locale: string;
  onClick: (article: ArticleSearchResultItem) => void;
};

export const knownIssueBadgeSkins: Partial<Record<string, BadgeSkin>> = {
  [ArticleResolution.INVESTIGATING]: 'neutralDanger',
  [ArticleResolution.THIRD_PARTY_BUG]: 'neutralDanger',
  [ArticleResolution.WORKING_ON_IT]: 'warningLight',
  [ArticleResolution.RESOLVED]: 'neutralSuccess',
};

export const KnownIssuesItem: FunctionComponent<KnownIssuesItemProps> = ({
  knownIssue,
  locale,
  onClick,
}) => {
  const { t } = useTranslation();
  const [publishedDate, setPublishedDate] = useState('');
  useEffect(() => {
    const creationDate =
      !isLong(knownIssue.creationDate)
        ? knownIssue.creationDate
        : new Long((knownIssue.creationDate).low, (knownIssue.creationDate).high).toNumber();

    setPublishedDate(moment
      .unix(Math.floor(creationDate / 1000))
      .locale(locale)
      .format(KI_PUBLISH_DATE_FORMAT));
  }, [knownIssue.creationDate, locale]);

  const knownIssueBadgeSkin = knownIssueBadgeSkins[knownIssue.resolution || ''];

  return (
    <Box
      dataHook={DATA_HOOKS.KNOWN_ISSUES_PAGE_ITEM}
      className={css.KnownIssuePageItem}
      direction="horizontal"
      align="space-between"
    >
      <Box
        className={css.KnownIssuePageDetails}
        direction="vertical"
        align="left"
      >
        <InnerLink
          className={css.KnownIssuePageItemTitleLink}
          href={knownIssue.uri}
          onClick={() => onClick(knownIssue)}
        >
          <Box className={css.ArticleIconDesktop}>
            <ArticleIcon />
          </Box>
          <Box className={css.ArticleIconMobile}>
            <ArticleIconMobile />
          </Box>
          <Text
            size="medium"
            weight="thin"
            className={css.KnownIssuePageItemTitle}
            dataHook={DATA_HOOKS.KNOWN_ISSUES_PAGE_ITEM_TITLE}
          >
            {knownIssue.title}
          </Text>
        </InnerLink>
        <Text
          size="small"
          weight="thin"
          className={css.KnownIssuePageItemPublishedDate}
          dataHook={DATA_HOOKS.KNOWN_ISSUES_PAGE_ITEM_PUBLISHED_DATE}
        >
          {t('known-issues-page.item.published-date', {
            publishedDate,
          })}
        </Text>
        <Badge
          skin={knownIssueBadgeSkin}
          size="tiny"
          className={css.KnownIssuePageItemStatusMobile}
        >
          {t(`known-issues-page.item.status.${knownIssue.resolution}`)}
        </Badge>
      </Box>
      <Box
        className={css.KnownIssuePageStatusAndGoTo}
        direction="horizontal"
        align="center"
        verticalAlign="middle"
      >
        <Tooltip
          enterDelay={TOOLTIP_ENTER_DELAY}
          maxWidth={290}
          content={t(
            `known-issues-page.item.status.${knownIssue.resolution}.tooltip`
          )}
        >
          <Badge
            skin={knownIssueBadgeSkin}
            size="tiny"
            className={css.KnownIssuePageItemStatusDesktop}
          >
            {t(`known-issues-page.item.status.${knownIssue.resolution}`)}
          </Badge>
        </Tooltip>
        <InnerLink
          className={css.KnownIssuePageGoTo}
          href={knownIssue.uri}
          dataHook={DATA_HOOKS.KNOWN_ISSUES_PAGE_ITEM_LINK}
          onClick={() => onClick(knownIssue)}
          ariaLabel={knownIssue.title}
        >
          <ArrowRightIcon className={css.KnownIssuePageGoToIcon} />
          <ArrowRightSmallIcon fontWeight={700} className={css.KnownIssuePageGoToIconMobile} />
        </InnerLink>
      </Box>
    </Box>
  );
};
