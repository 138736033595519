import { ArticleResolution, ArticleSortType } from '@wix/answers-api';
import {
  Box,
  CounterBadge,
  Dropdown,
  DropdownLayoutOption,
  Heading,
  IconButton,
  Input,
  Modal,
  MultiSelectCheckbox,
  Search,
  Text,
  listItemSectionBuilder,
} from '@wix/design-system';
import { useTranslation } from '@wix/wix-i18n-config';
import { FunctionComponent, MutableRefObject, useCallback, useContext, useMemo, useState } from 'react';
import { ChangeOrderSmall } from 'wix-ui-icons-common';
import { MAX_SEARCH_LENGTH, MOVE_TO_TOP_Z_INDEX, SEARCH_DEBOUNCE, TOOLTIP_ENTER_DELAY } from '../../../constants';
import { Context } from '../../../context';
import { DATA_HOOKS } from '../../../dataHooks';
import { KNOWN_ISSUES_RELEVANT_RESOLUTIONS } from '../../../types';
import { COLORS } from '../../../utils/theme';
import { isMobile } from '../../../utils/userAgent';
import { RenderHTML } from '../../RenderHTML';
import { KnownIssuesFilterModal } from './KnownIssuesFilterModal';
import css from './index.module.scss';
import { useIsIntersecting } from '../../../hooks/useIsIntersecting';

export type FilterBy = {
  status: ArticleResolution[];
  myIssues?: boolean;
};

export type FilterByOption = 'all' | 'following' | ArticleResolution;

export enum SortBy {
  NEWEST = ArticleSortType.CREATION_DATE_DESC,
  LAST_UPDATED = ArticleSortType.LAST_PUBLISH_DATE_DESC,
  MOST_RELEVANT = ArticleSortType.TEXTUAL_SCORE,
}

export const getKnownIssuesSortByOptions = (hasSearchQuery?: boolean) =>
  (hasSearchQuery ? [ArticleSortType.TEXTUAL_SCORE] : []).concat([
    ArticleSortType.CREATION_DATE_DESC,
    ArticleSortType.LAST_PUBLISH_DATE_DESC,
  ]);


export type SearchAndFilterPanelProps = {
  numberOfKnownIssues: number;
  searchQuery?: string;
  filterBy: FilterBy;
  sortBy: SortBy;
  breadCrumbsRef: MutableRefObject<any>;
  onApplyFiltersAndSort: (newFilterBy: FilterBy, newSortBy: SortBy, newAddedFilters: FilterByOption[]) => void;
  onSearchQueryChange: (searchQuery: string) => void;
};

export const SearchAndFilterPanel: FunctionComponent<
  SearchAndFilterPanelProps
> = ({
  numberOfKnownIssues,
  searchQuery,
  filterBy,
  sortBy,
  breadCrumbsRef,
  onApplyFiltersAndSort,
  onSearchQueryChange,
}) => {
  const { t } = useTranslation();
  const [isFilterAndSortModalOpen, setIsFilterAndSortModalOpen] =
    useState(false);

  const context = useContext(Context);
  const isMobileView = isMobile(context);
  const numberOfActiveFiltersAndSorts = useMemo(() =>
    filterBy.status.length + (filterBy.myIssues ? 1 : 0)
  , [filterBy]);

  const marginTop = useMemo(() => isMobile() ? '-54px' : '-71px', []);
  const isScrolling = useIsIntersecting(breadCrumbsRef, marginTop);
  const getSortByOptions = useCallback(() => {
    const FILTER_BY_OPTIONS = [
      listItemSectionBuilder({
        title: t('known-issues-page.filter-by.status'),
        id: 'status',
      }),
      {
        value: t('known-issues-page.status.all'),
        id: 'all',
      },
      ...KNOWN_ISSUES_RELEVANT_RESOLUTIONS.map((item) => ({
        value: t(`known-issues-page.status.${item}`),
        id: item,
      })),
      listItemSectionBuilder({
        title: t('known-issues-page.filter-by.my-issues'),
        id: 'my-issues',
      }),
      {
        value: t('known-issues-page.filter-by.following'),
        id: 'following',
      },
    ];
    return FILTER_BY_OPTIONS as DropdownLayoutOption[];
  }, [t]);

  const SORT_OPTIONS = useMemo(() =>
  getKnownIssuesSortByOptions(!!searchQuery?.length).map((item) => ({
    value: t(`known-issues-page.sort-by.${item}`),
    id: item,
  })), [searchQuery?.length, t]);

  const onFilterBySelectOrDeselect = useCallback((
    option: FilterByOption,
    deselect?: boolean
  ) => {
    const newFilterBy = !deselect ? [option] : [];
    if (option === 'all') {
      const updatedFilterBy = {
        ...filterBy,
        status: [],
      };
      onApplyFiltersAndSort(updatedFilterBy, sortBy, newFilterBy);
    } else if (option === 'following') {
      const updatedFilterBy = {
        ...filterBy,
        myIssues: !filterBy.myIssues,
      };
      onApplyFiltersAndSort(updatedFilterBy, sortBy, newFilterBy);
    } else {
      const updatedFilterBy = {
        ...filterBy,
        status: filterBy.status.includes(option)
          ? filterBy.status.filter((item) => item !== option)
          : [...filterBy.status, option],
      };
      onApplyFiltersAndSort(updatedFilterBy, sortBy, newFilterBy);
    }
  }, [filterBy, onApplyFiltersAndSort, sortBy]);

  const numberOfItemsText = useCallback((mobile?: boolean) => {
    const withQueryPrefix = searchQuery ? '-with-query' : '';
    const withMobileSuffix = mobile ? '.mobile' : '';
    const singleSuffix = Number(numberOfKnownIssues) === 1 ? '.single' : '';
    const prefix = `known-issues-page.number-of-items${withQueryPrefix}${withMobileSuffix}${singleSuffix}`;
    return t(prefix, {
      numberOfKnownIssues,
      searchQuery,
    });
}, [numberOfKnownIssues, searchQuery, t]);

  const selectedFilterByOptions = useMemo(() => {
    const selectedFilterBy = filterBy.status.length ? filterBy.status : ['all'];
    return [
    ...selectedFilterBy,
    filterBy.myIssues ? 'following' : undefined,
  ] as FilterByOption[];
 } , [filterBy.myIssues, filterBy.status]);

  return (
    <Box
      dataHook={DATA_HOOKS.KNOWN_ISSUES_PAGE_SEARCH_AND_FILTER_PANEL}
      className={`${css.KnownIssuePageSearchAndFilterPanel} ${
        isScrolling ? css.KnownIssuePageSearchAndFilterPanelSticky : ''
      }`}
      direction="vertical"
      align="space-between"
    >
      <Box className={css.KnownIssuePageHeader} direction="vertical">
        <Heading
          size="extraLarge"
          className={css.KnownIssuePageTitle}
          dataHook={DATA_HOOKS.KNOWN_ISSUES_PAGE_TITLE}
        >
          {t('known-issues-page.title')}
        </Heading>
        <Text
          size="medium"
          weight="normal"
          className={css.KnownIssuePageDescription}
          dataHook={DATA_HOOKS.KNOWN_ISSUES_PAGE_DESCRIPTION}
        >
          {t('known-issues-page.description')}
        </Text>
      </Box>
      <Box direction="horizontal" gap="SP1" verticalAlign="middle">
        <Search
          value={searchQuery}
          border={isMobileView ? 'round' : 'bottomLine'}
          dataHook={DATA_HOOKS.KNOWN_ISSUES_PAGE_SEARCH}
          onChange={(e) => onSearchQueryChange(e.target.value)}
          placeholder={t('known-issues-page.search-placeholder')}
          debounceMs={SEARCH_DEBOUNCE}
          forceFocus={!isMobileView}
          autoFocus={!isMobileView}
          onClear={() => onSearchQueryChange('')}
          maxLength={MAX_SEARCH_LENGTH}
        />
        <Box className={css.KnownIssuePageSortByMobile} direction="horizontal">
          <IconButton
            dataHook={DATA_HOOKS.MOBILE_SORT_ICON_BUTTON}
            className={css.modalButton}
            priority="secondary"
            size={isMobileView ? 'tiny' : 'small'}
            onClick={() => setIsFilterAndSortModalOpen(true)}
          >
            <ChangeOrderSmall color={COLORS.B10} />
          </IconButton>
          {numberOfActiveFiltersAndSorts ? (
            <div className={css.counterBadge}>
              <CounterBadge skin="neutralStandard" dataHook={DATA_HOOKS.KNOWN_ISSUES_PAGE_FILTERS_COUNT}>
                {numberOfActiveFiltersAndSorts}
              </CounterBadge>
            </div>
          ) : null}
        </Box>
      </Box>

      <RenderHTML
        textElement={{
          size: 'tiny',
          weight: 'thin',
          ellipsis: true,
          tooltipProps: {
            enterDelay: TOOLTIP_ENTER_DELAY,
          },
          className: css.NumberOfResultsMobile,
        }}
        className={css.NumberOfResultsMobile}
        html={numberOfItemsText(true)}
      />
      <Box
        className={css.KnownIssuePageFilterAndSort}
        direction="horizontal"
        align="space-between"
        verticalAlign="middle"
        gap='SP4'
      >
        <RenderHTML
          dataHook={DATA_HOOKS.KNOWN_ISSUES_PAGE_SEARCH_NUMBER_OF_ITEMS}
          className={css.NumberOfResults}
          html={numberOfItemsText(false)}
          textElement={{
            size: 'medium',
            weight: 'thin',
            ellipsis: true,
            tooltipProps: {
              enterDelay: TOOLTIP_ENTER_DELAY,
              placement: 'bottom'
            },
          }}
        />
        <Box className={css.KnownIssuePageFilterAndSortDropdowns} gap={'SP3'}>
          <MultiSelectCheckbox
            border="round"
            className={css.KnownIssuePageFilterBy}
            onSelect={(id) => onFilterBySelectOrDeselect(id as FilterByOption)}
            onDeselect={(id) =>
              onFilterBySelectOrDeselect(id as FilterByOption, true)
            }
            dataHook={DATA_HOOKS.KNOWN_ISSUES_PAGE_FILTER_BY}
            options={getSortByOptions()}
            selectedOptions={selectedFilterByOptions}
            prefix={
              <Input.Affix>{t('known-issues-page.filter-by')}</Input.Affix>
            }
          />
          <Dropdown
            border="round"
            className={css.KnownIssuePageSortBy}
            prefix={<Input.Affix>{t('known-issues-page.sort-by')}</Input.Affix>}
            dataHook={DATA_HOOKS.KNOWN_ISSUES_PAGE_SORT_BY}
            options={SORT_OPTIONS}
            onSelect={(option) => onApplyFiltersAndSort(filterBy, option.id as SortBy, [])}
            selectedId={sortBy}
          />
        </Box>
      </Box>
      <Modal
        dataHook={DATA_HOOKS.FILTER_AND_SORT_MODAL}
        isOpen={isFilterAndSortModalOpen}
        onRequestClose={() => setIsFilterAndSortModalOpen(false)}
        screen="mobile"
        shouldCloseOnOverlayClick
        zIndex={MOVE_TO_TOP_Z_INDEX}
      >
        <KnownIssuesFilterModal
          filterBy={filterBy}
          sortBy={sortBy}
          onApplyFiltersAndSort={onApplyFiltersAndSort}
          onClose={() => setIsFilterAndSortModalOpen(false)}
          hasSearchQuery={!!searchQuery?.length}
        />
      </Modal>
    </Box>
  );
};
