import {
  Box,
  Button,
  Checkbox,
  CustomModalLayout,
  RadioGroup,
  Text,
} from '@wix/design-system';
import { useTranslation } from '@wix/wix-i18n-config';
import { FunctionComponent, useState } from 'react';
import { FilterBy, FilterByOption, getKnownIssuesSortByOptions, SortBy } from '..';
import { DATA_HOOKS } from '../../../../dataHooks';
import { KNOWN_ISSUES_RELEVANT_RESOLUTIONS } from '../../../../types';
import css from './index.module.scss';

export type KnownIssuesFilterModalProps = {
  hasSearchQuery: boolean;
  filterBy: FilterBy;
  sortBy: SortBy;
  onApplyFiltersAndSort: (
    newFilterBy: FilterBy,
    newSortBy: SortBy,
    newAddedFilters: FilterByOption[]
  ) => void;
  onClose: () => void;
};

export const KnownIssuesFilterModal: FunctionComponent<
  KnownIssuesFilterModalProps
> = ({ hasSearchQuery, filterBy, sortBy, onApplyFiltersAndSort, onClose }) => {
  const { t } = useTranslation();

  const [selectedFilters, setSelectedFilters] = useState(filterBy);
  const [selectedSort, setSelectedSort] = useState(sortBy);

  const onApply = () => {
    const newAddedFilters = selectedFilters.status.filter(
      (status) => !filterBy.status.includes(status)
    );
    const isFollowingSelected = selectedFilters.myIssues && !filterBy.myIssues;
    const newAddedFilter = [
      ...newAddedFilters,
      ...(isFollowingSelected ? ['following'] : []),
    ] as FilterByOption[];

    if (selectedFilters !== filterBy || selectedSort !== sortBy) {
      onApplyFiltersAndSort(selectedFilters, selectedSort, newAddedFilter);
    }
    onClose();
  };

  const onFilterBySelectOrDeselect = (option: FilterByOption) => {
    if (option === 'all') {
      setSelectedFilters({
        ...selectedFilters,
        status: [],
      });
    } else if (option === 'following') {
      setSelectedFilters({
        ...selectedFilters,
        myIssues: !selectedFilters.myIssues,
      });
    } else {
      const selectedStatus = selectedFilters.status.includes(option)
        ? selectedFilters.status.filter((status) => status !== option)
        : [...selectedFilters.status, option];
      setSelectedFilters({
        ...selectedFilters,
        status: selectedStatus,
      });
    }
  };

  const onSortChange = (selectedSortBy: SortBy) => {
    setSelectedSort(selectedSortBy);
  };

  const selectedFilterBy = selectedFilters.status.length
    ? selectedFilters.status
    : ['all'];
  const selectedFilterByOptions = [
    ...selectedFilterBy,
    selectedFilters.myIssues ? 'following' : undefined,
  ] as FilterByOption[];

  return (
    <CustomModalLayout
      dataHook={DATA_HOOKS.KNOWN_ISSUES_PAGE_FILTER_MODAL}
      className={css.filterAndSortModalBody}
      onCloseButtonClick={onClose}
      title={t('known-issues-page.filter-modal.title')}
      content={
        <Box direction="vertical" gap="SP5" className={css.menu}>
          <Box
            direction="vertical"
            gap="SP2"
            dataHook={DATA_HOOKS.KNOWN_ISSUES_PAGE_FILTER_MODAL_FILTER_BY}
          >
            <Text size="small" weight="thin" className={css.GroupLabel}>
              {t('known-issues-page.filter-by.status')}
            </Text>
            {['all', ...KNOWN_ISSUES_RELEVANT_RESOLUTIONS].map((status) => (
              <Checkbox
                dataHook={`${DATA_HOOKS.KNOWN_ISSUES_PAGE_FILTER_MODAL_FILTER_BY}_${status}`}
                onChange={() =>
                  onFilterBySelectOrDeselect(status as FilterByOption)
                }
                key={status}
                checked={selectedFilterByOptions.includes(
                  status as FilterByOption
                )}
              >
                <Text size="medium" className={css.Option}>
                  {t(`known-issues-page.status.${status}`)}
                </Text>
              </Checkbox>
            ))}
          </Box>

          <Box direction="vertical" gap="SP2">
            <Text size="small" weight="thin" className={css.GroupLabel}>
              {t('known-issues-page.filter-by.my-issues')}
            </Text>
            <Checkbox
              onChange={() => onFilterBySelectOrDeselect('following')}
              checked={selectedFilterByOptions.includes('following')}
              dataHook={`${DATA_HOOKS.KNOWN_ISSUES_PAGE_FILTER_MODAL_FILTER_BY}_following`}
            >
              <Text size="medium" className={css.Option}>
                {t('known-issues-page.filter-by.following')}
              </Text>
            </Checkbox>
          </Box>

          <Box direction="vertical" gap="SP2">
            <Text size="small" weight="thin" className={css.GroupLabel}>
              {t('known-issues-page.modal.sort-by')}
            </Text>
            <RadioGroup
              value={selectedSort}
              onChange={(val) => onSortChange(val as SortBy)}
              dataHook={DATA_HOOKS.KNOWN_ISSUES_PAGE_FILTER_MODAL_SORT_BY}
            >
              {getKnownIssuesSortByOptions(hasSearchQuery).map((item) => (
                <RadioGroup.Radio key={item} value={item}>
                  <Text size="medium" className={css.Option}>
                    {t(`known-issues-page.sort-by.${item}`)}
                  </Text>
                </RadioGroup.Radio>
              ))}
            </RadioGroup>
          </Box>

          <Button
            className={css.applyButton}
            onClick={onApply}
            dataHook={DATA_HOOKS.KNOWN_ISSUES_PAGE_FILTER_MODAL_APPLY}
          >
            {t('search-results.filter-modal.apply')}
          </Button>
        </Box>
      }
    />
  );
};
